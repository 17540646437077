<template>
  <AboutSection :nav-id="sectionId" :bg-class="bgClass" :reversed="true">
    <h2 class="patky">
      Už se chystáte?
    </h2>
    <p>
      Jak se na svatbu dostat, najdete&nbsp;<router-link :to="`/${departureLink}`">zde</router-link>.
    </p>
    <p>
      Příjezd naplánujte nejlépe před polednem. Na uvítanou dostanete malé občerstvení, následně se ubytujete a pak už se půjde na věc!
    </p>
    <p>Hostům ze Znojma a okolí, kteří nebudou ubytovaní, bude zajištěn odvoz.</p>
    <p>
      Co se oblečení týče, budeme rádi, když zvolíte pastelové barvy. Družičky jistě obdrží pokyny
      od nevěsty.
    </p>
    <p>No a pánové, jako vždycky...co mají doma :)</p>


    <p>Největším darem pro nás bude, že s námi strávíte náš svatební den. Pokud si přesto lámete hlavu nad svatebním darem, vězte, že naše domácnost je po těch letech plně vybavená. Nejvíc nás potěší něco malého, co se vejde do obálky.</p>
    <p>
      <a href="https://www.kocandakravsko.cz/" target="_blank">Tady</a> se můžete podívat, kde se bude svatba konat.
    </p>
  </AboutSection>
</template>
<script>
import AboutSection from "@/components/about/AboutSection.vue";
import { getId, MENU } from "@/data";

export default {
  name: 'PrepareSection',
  components: {AboutSection},
  data() {
    return {
      sectionId: getId(MENU.prepare.to),
      departureLink: MENU.departure.to,
      bgClass: MENU.prepare.bgClass,
    };
  }
}
</script>