export const MENU = {
    rsvp: {bgClass: 'rsvp', title: "Přijedete?", to: "#rsvp"},
    prepare: {bgClass: 'prepare', title: "Chystáme se", to: "#chystame_se"},
    departure: {bgClass: 'departure', title: "Vyrážíme", to: "#vyrazime"},
    arrival: {bgClass: 'arrival', title: "Přijeli jsme", to: "#prijeli_jsme"},
    start: {bgClass: 'start', title: "Už to začalo", to: "#uz_to_zacalo"},
    after: {bgClass: 'after', title: "A je po všem", to: "#a_je_po_vsem"},
    contact: {bgClass: 'contact', title: "Kontakt", to: '#kontakt'}
}

/**
 *
 * @param {String} to
 * @returns {String}
 */
export function getId(to) {
    return to.replace('#', '')
}