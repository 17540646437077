<template>
  <AboutSection :nav-id="sectionId" :bg-class="bgClass" :reversed="true">
    <h2 class="patky">
      A je po všem...uf
    </h2>
    <p>
      Ráno rozhodně přijďte na snídani. Povíme si zážitky a zasmějeme se, jak kdo dopadl.
    </p>
    <p>
      Pokud se chcete zdržet a užít si krásy Znojemska, <a href="https://smilingway.cz/co-videt-ve-znojme/" target="_blank">zde</a> je pár tipů, co navštívit.
    </p>
    <p>
      Pokud jste nacvakali nějaké fotky, budeme rádi, když nám je nasdílíte. Později se zde objeví odkaz, kam bude možné fotky nahrát.
    </p>

    <p>
      Zároveň tady po svatbě budou svatební fotografie.
    </p>
  </AboutSection>
</template>
<script>
import AboutSection from "./AboutSection.vue";
import { getId, MENU } from "@/data";

export default {
  name: 'AfterSection',
  components: {AboutSection},
  data() {
    return {
      sectionId: getId(MENU.after.to),
      bgClass: MENU.after.bgClass,
    };
  }
}
</script>