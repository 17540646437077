<template>
  <!-- Start Single Slider -->
  <div>
    <div
      class="slider slider--3 ptb--200 main_image_bg"
      v-for="(slider, i) in sliderContent"
      :key="i"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="content">
              <h3 class="heading-title patky">
                <span class="month patky">červen</span>
                <span class="day patky">21</span>
                <span class="year patky">2025</span>
              </h3>
              <h1 class="patky">{{ slider.title }}</h1>
              <img class="main_image" :src="imgSrc" alt="Svatební pár">
              <ActionButton />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
</template>


<script>
// kuba used
  import ActionButton from "../wedding/ActionButton.vue";

  export default {
    components: {
      ActionButton
    },
    data() {
      return {
        imgSrc: require('../../assets/img/bg/bg-image-8-small.jpg'),
        sliderContent: [
          {
            imgClass: "main_image_bg",
            title: "Zuzka & Kuba",
          },
        ],
      };
    },
  };
</script>

<style lang="scss">
  .heading-title {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .day {
      margin: 0 20px;
      font-size: 55px;
    }
    .month, .year {
      padding: 1px 0;
      border-bottom: 1px solid;
      border-top: 1px solid;
      line-height: 45px;
      font-size: 38px;
    }
  }

  .main_image_bg {
    background-image: url('../../assets/img/bg/bg-image-8.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .slide-btn {
    margin-top: 40px;
  }

  .main_image {
    display: none;
  }

  @media only screen and (max-width: 575px) {
    .content {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-size: 20px !important;
        line-height: 43px !important;
      }

      h1 {
        font-size: 35px !important;
        line-height: 35px !important;
      }
    }

    .main_image_bg {
      background-image: none;
    }

    .main_image {
      display: block;
      object-fit: cover;
      height: 40vh;
    }

    .slide-btn {
      margin-top: 20px;
    }
  }
</style>
