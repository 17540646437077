<template>
  <div class="about-page">
    <RvspSection />
    <PrepareSection />
    <DepartureSection />
    <ArrivalSection />
    <ProgramSection />
    <AfterSection />
  </div>
</template>

<script>
// kuba used
import RvspSection  from "./RvspSection.vue";
import PrepareSection from "./PrepareSection.vue";
import DepartureSection from "./DepartureSection.vue";
import ArrivalSection from "./ArrivalSection.vue";
import ProgramSection from "./ProgramSection.vue";
import AfterSection from "./AfterSection.vue";

export default {
  components: {
    AfterSection,
    ProgramSection,
    ArrivalSection,
    DepartureSection,
    PrepareSection,
    RvspSection,
  }
};
</script>
