<template>
  <AboutSection :nav-id="sectionId" :bg-class="bgClass" :reversed="true">
    <h2 class="patky">
      Dorazili jste na místo! Hurá!
    </h2>
    <p>
      Z parkoviště projdete malým průchodem do areálu, kde na vás bude čekat občerstvení. Nebojte, někdo se vás jistě ujme.
    </p>
    <p>
      Zároveň je teď vhodná chvíle se ubytovat, dát si kávu nebo něco ostřejšího.
    </p>
    <!-- <p>Kolem půl jedné se začnete přesouvat na obřad. (Někdo na Vás začne hulákat, abyste se zvedli:)</p> -->
  </AboutSection>
</template>
<script>
import AboutSection from "@/components/about/AboutSection.vue";
import { getId, MENU } from "@/data";

export default {
  name: 'ArrivalSection',
  components: {AboutSection},
  data() {
    return {
      sectionId: getId(MENU.arrival.to),
      bgClass: MENU.arrival.bgClass,
    };
  }
}
</script>