<template>
  <div class="rn-story-area d-flex" :class="(reversed ? 'bg_color--1 story-reversed' : ' bg_color--3')" :id="navId">
    <div
        class="rn-story-left w-50 rn-story-bg-wrapper"
        :class="bgClass"
    ></div>
    <div class="rn-story-right w-50 d-flex align-items-center">
      <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
      >
        <div class="content text-left">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['navId', 'bgClass', 'reversed']
}
</script>

<style lang="scss" scoped>
.story-reversed {
  flex-direction: row-reverse;
}
</style>