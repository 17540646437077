<template>
  <div class="slide-btn">
    <router-link class="cta-btn rf-btn" :to="MENU.rsvp.to"
    >Dej nám vědět, jestli přijedete
    </router-link
    >
  </div>
</template>
<script>
import { MENU } from "@/data";

export default {
  data() {
    return {
      MENU,
    }
  }
}
</script>

<style lang="scss">
 .cta-btn.rf-btn {
   background: #fff0e5;
   font-weight: bold;
   color: black !important;
 }
</style>