<template>
  <!-- Start Awards Area -->
  <div class="rn-award-area d-flex bg_color--3" id="awards">
    <div class="text-left award-content">
      <ul v-if="programReady" class="tabs rn-award-list">
        <li v-for="(item, i) in items" :key="i">
            <span>{{item.description}}</span><br />
            {{item.time}}
        </li>
      </ul>
      <div v-else>
        ...se dozvíte již brzy
      </div>

    </div>
  </div>
  <!-- End Awards Area -->
</template>

<script>
  export default {
    name: 'ProgramList',
    data() {
      return {
        programReady: false,
        items: [
          {
            time: '11:30',
            description: 'Příjezd a uvítání hostů'
          },
          {
            time: '13:00',
            description: 'Začíná obřad'
          },
          {
            time: '14:00',
            description: 'Začíná žranice'
          },
          {
            time: '16:00',
            description: 'Zákusek, siesta'
          },
          {
            time: '17:00',
            description: 'Začíná párty'
          },
          {
            time: '21:00',
            description: 'TZ'
          },
          {
            time: '22:00',
            description: 'Začíná afterparty'
          },

        ]
      };
    },
  };
</script>

<style lang="scss" scoped>
  .rn-award-area {
    overflow: hidden;
  }
  .award-content ul.rn-award-list li a:focus {
    outline: none;
  }
</style>
