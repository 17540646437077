<template>
  <AboutSection :nav-id="sectionId" :bg-class="bgClass">
    <h2 class="patky">
      Vyrážíme!
    </h2>
    <p>
      Ať už to máte kousek, nebo jedete z dálek, přejeme vám šťastnou cestu!
    </p>
    <p>
      Parkoviště je dostatečně velké, určitě se všichni vejdete.
    </p>
    <p>
      Adresa je <span :class="'address' + (copied ? ' address--copied' : '')">
      {{ address }}
      <button v-if="displayCopyButton" class="copy-button" @click="copyAddress">
        <CopyIcon/>
      </button>
    </span>
    </p>

    <div
        class="story-btn wow newFadeInUp"
        data-wow-duration="1s"
        data-wow-delay="1000ms"
    >
      <a :href="navigationUrl" target="_blank">Navigovat</a>
    </div>

  </AboutSection>
</template>
<script>
import AboutSection from "./AboutSection.vue";
import { getId, MENU } from "@/data";
import { copyToClipboard } from '@/utils'
import CopyIcon from "../icons/CopyIcon.vue";

export default {
  name: 'DepartureSection',
  components: {
    CopyIcon,
    AboutSection
  },
  data() {
    return {
      address: 'Kravsko 45, 671 51 Kravsko',
      navigationUrl: 'https://maps.app.goo.gl/rBYC2ESyHicWN3Rh9',
      bgClass: MENU.departure.bgClass,
      sectionId: getId(MENU.departure.to),
      displayCopyButton: !!navigator.clipboard && !!navigator.clipboard.writeText,
      copied: false,
    }
  },
  methods: {
    copyAddress() {
      void copyToClipboard(this.address)

      this.copied = true;
      setTimeout(() => {
        this.copied = false
      }, 2000)
    }
  }

}
</script>

<style lang="scss" scoped>
.address {
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  transition: fill 0.2s ease, color 0.2s ease;

}

.copy-button {
  border: none;
  display: inline-flex;
  align-items: center;
  margin-left: 3px;
}

.address--copied, .address--copied svg {
  color: #b2b485;
  fill: #b2b485;
}
</style>